/* eslint-disable import/prefer-default-export */
import React from 'react';

import { CheckIcon } from '@chakra-ui/icons';
import { Box, Flex, Icon, useRadio, UseRadioProps } from '@chakra-ui/react';

import { formatEnumValue } from '@/utils/formatEnumValue';

import { P } from '../text';

export const SingleCheckbox = (props: UseRadioProps) => {
  const {
    state,
    getInputProps,
    getRootProps,
    htmlProps,
    getLabelProps,
    getCheckboxProps,
  } = useRadio(props);
  const { value } = props;

  return (
    <Box as='label' cursor='pointer' {...htmlProps}>
      <input {...getInputProps({})} />
      <Flex alignItems='center' {...getRootProps()}>
        <Flex
          alignItems='center'
          bg={state.isChecked ? 'blue.500' : 'white'}
          border='2px solid'
          borderColor={state.isChecked ? 'blue.500' : 'gray.300'}
          justifyContent='center'
          minH='20px'
          minW='20px'
          mr={2}
          rounded='3px'
          {...getCheckboxProps()}
        >
          {state.isChecked && <Icon as={CheckIcon} color='white' h={3} w={3} />}
        </Flex>
        <P color='gray.500' {...getLabelProps()}>
          {formatEnumValue(value)}
        </P>
      </Flex>
    </Box>
  );
};
