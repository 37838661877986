import { FC } from 'react';

import {
  Link as ChakraLink,
  LinkProps as ChakraLinkProps,
} from '@chakra-ui/react';
// eslint-disable-next-line import/no-named-default
import { default as NextLink, LinkProps as NextLinkProps } from 'next/link';

export type LinkProps = ChakraLinkProps & NextLinkProps;

export const Link: FC<LinkProps> = ({ ...restProps }) => {
  return <ChakraLink as={NextLink} {...restProps} />;
};

export const A = ChakraLink;
