import { LanguageCodeEnum } from '@/generated/core.graphql';

type LanguageListItem = {
  language: string;
  languageCode: LanguageCodeEnum;
  countryCode: string;
};

const LanguagesList: LanguageListItem[] = [
  // {
  //   language: 'English (UK)',
  //   languageCode: LanguageCodeEnum.EnGb,
  //   countryCode: 'GB',
  // },
  {
    language: 'English (US)',
    languageCode: LanguageCodeEnum.EnUs,
    countryCode: 'US',
  },
  {
    language: 'Português',
    languageCode: LanguageCodeEnum.Pt,
    countryCode: 'PT',
  },
  {
    language: 'Español',
    languageCode: LanguageCodeEnum.Es,
    countryCode: 'ES',
  },
  // {
  //   language: 'French',
  //   languageCode: LanguageCodeEnum.Fr,
  //   countryCode: 'FR',
  // },
  // {
  //   language: 'Italian',
  //   languageCode: LanguageCodeEnum.It,
  //   countryCode: 'IT',
  // },
  // {
  //   language: 'Danish',
  //   languageCode: LanguageCodeEnum.Da,
  //   countryCode: 'DK',
  // },
  // {
  //   language: 'Hindi',
  //   languageCode: LanguageCodeEnum.Hi,
  //   countryCode: 'IN',
  // },
  // {
  //   language: 'German',
  //   languageCode: LanguageCodeEnum.De,
  //   countryCode: 'DE',
  // },
  // {
  //   language: 'Polish',
  //   languageCode: LanguageCodeEnum.Pl,
  //   countryCode: 'PL',
  // },
  // {
  //   language: 'Romanian',
  //   languageCode: LanguageCodeEnum.Ro,
  //   countryCode: 'RO',
  // },
  // {
  //   language: 'Dutch',
  //   languageCode: LanguageCodeEnum.Nl,
  //   countryCode: 'NL',
  // },
  // {
  //   language: 'Swedish',
  //   languageCode: LanguageCodeEnum.Sv,
  //   countryCode: 'SE',
  // },
];
export default LanguagesList;
