/* eslint-disable import/prefer-default-export */
import { FC, useEffect, useState, useCallback, memo } from 'react';

import { SearchIcon } from '@chakra-ui/icons';
import {
  Box,
  Checkbox,
  Button,
  Input,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Divider,
  Flex,
  InputGroup,
  InputLeftElement,
  ButtonProps,
  Stack,
  Text,
} from '@chakra-ui/react';

import LoadingSpinner from '@/components/Loading/LoadingSpinner';

type SelectType = 'single' | 'multi';

type SelectMenuProps = {
  options: { label: string; value: string; isDeleted?: boolean }[];
  handleSelect: (value: string) => Promise<void>;
  title?: string;
  isChecked?: string[];
  loading?: boolean;
  withSearch?: boolean;
  handleSearch?: (v: string) => void;
  menuButtonProps?: ButtonProps;
  selectType?: SelectType;
};

export const SelectMenu: FC<SelectMenuProps> = memo(
  ({
    options = [],
    isChecked,
    title = 'Select',
    handleSelect,
    withSearch = true,
    menuButtonProps,
    loading,
    selectType = 'multi',
    ...props
  }) => {
    const [selectedOptions, setSelectedOptions] = useState(isChecked || []);
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredOptions, setFilteredOptions] = useState(options);

    useEffect(() => {
      setFilteredOptions(options);
      setSelectedOptions(isChecked || []);
    }, [options]);

    const handleChange = useCallback(
      async (selected) => {
        if (handleSelect) {
          await handleSelect(selected.value);
        }
        if (selectType === 'single') {
          setSelectedOptions([selected.value]);
          return;
        }
        setSelectedOptions((prevSelectedOptions) => {
          if (prevSelectedOptions.includes(selected.value)) {
            return prevSelectedOptions.filter(
              (option) => option !== selected.value
            );
          }
          return [...prevSelectedOptions, selected.value];
        });
      },
      [handleSelect, selectType]
    );

    const searchOptions = useCallback(
      (searchValue: string) => {
        setSearchQuery(searchValue);
        setFilteredOptions(
          options.filter((option) =>
            option.label.toLowerCase().includes(searchValue.toLowerCase())
          )
        );
      },
      [options]
    );

    const formatSelectedOptions = (): JSX.Element => {
      if (selectedOptions?.length && options?.length) {
        const selectedClientNames = selectedOptions.map((option) => {
          const selected = options.find((o) => o.value === option);
          return selected?.label;
        });

        return (
          <Stack alignItems='start'>
            {selectedClientNames.sort().map((clientName) => (
              <Text key={clientName}>{clientName}</Text>
            ))}
          </Stack>
        );
      }
      return <Stack />;
    };

    return (
      <Menu closeOnSelect={false} {...props}>
        {options.length ? (
          <MenuButton
            as={Button}
            color='electricBlue.500'
            fontSize='base'
            variant='link'
            {...menuButtonProps}
          >
            {selectedOptions?.length ? formatSelectedOptions() : title}
          </MenuButton>
        ) : (
          <Box
            color='electricBlue.500'
            cursor='not-allowed'
            fontSize='base'
            fontWeight={600}
          >
            {title}
          </Box>
        )}
        <MenuList>
          {withSearch && (
            <Box padding={2}>
              <InputGroup>
                <InputLeftElement pointerEvents='none'>
                  <SearchIcon color='gray.300' />
                </InputLeftElement>
                <Input
                  defaultValue={searchQuery}
                  onChange={(e) => searchOptions(e.target.value)}
                  placeholder='Search...'
                />
              </InputGroup>
              <Divider mt='sm' />
            </Box>
          )}
          {loading && <LoadingSpinner isFloating size='lg' />}
          <Flex direction='column' maxH='160px' overflowY='auto'>
            {filteredOptions?.map(
              (option) =>
                (!option.isDeleted ||
                  selectedOptions?.includes(option.value)) && (
                  <MenuItem key={option.value} disabled={loading}>
                    <Checkbox
                      isChecked={selectedOptions?.includes(
                        option.value.toString()
                      )}
                      marginRight={2}
                      onChange={() => handleChange(option)}
                      value={option.value}
                    >
                      {option.label}
                    </Checkbox>
                  </MenuItem>
                )
            )}
          </Flex>
        </MenuList>
      </Menu>
    );
  }
);
