import React, { useEffect, useState } from 'react';

import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  useToast,
} from '@chakra-ui/react';
import Image from 'next/image';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import { useForm } from 'react-hook-form';
import { IoEye, IoClose } from 'react-icons/io5';

import LoginImage from '@/assets/images/loginImage.jpg';
import Logo from '@/assets/images/logo.png';
import FooterLinks from '@/components/Auth/FooterLinks';
import LanguageSelector from '@/components/Languages/LanguageSelector';
import { useAuth } from '@/context/AuthContext';
import { Button, Link } from '@/elements';
import { UserRole } from '@/generated/core.graphql';

export default function SignIn() {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const router = useRouter();
  const { source, url } = router.query;
  const { isAuthenticated, signIn, isSigningIn, signInError, userRole } =
    useAuth();
  const toast = useToast({ isClosable: true });
  const { t } = useTranslation('auth');

  const [show, setShow] = useState(false);

  const onSubmit = async (values) => {
    if (values.email && values.password) {
      signIn(values.email, values.password);
    }
  };

  // redirect if signed in
  useEffect(() => {
    if (isAuthenticated) {
      if (url) {
        router.push(url as string);
      }
      if (userRole === UserRole.User) {
        router.push('/participant');
      } else {
        router.push('/admin/clients');
      }
    }
  }, [isAuthenticated, router, url, userRole]);

  useEffect(() => {
    if (!router.isReady) {
      return;
    }

    if (router.query.email) {
      setValue('email', router.query.email);
    }

    if (source === 'resetPassword') {
      toast({
        status: 'success',
        title: t('toast.resetTitle'),
        description: t('toast.resetDescription'),
        variant: 'top-accent',
        position: 'top-right',
      });
    }
  }, [isAuthenticated, router, source, toast, t, setValue]);

  useEffect(() => {
    if (signInError) {
      toast({
        status: 'error',
        title: t('toast.signInTitle'),
        description: t('toast.signInDescription'),
        variant: 'top-accent',
        position: 'top-right',
      });
    }
  }, [signInError, t, toast]);

  const toggleShowPassword = () => setShow(!show);

  return (
    <Flex
      backgroundColor='white'
      justifyContent='space-around'
      minHeight='100vh'
    >
      <Flex
        alignItems='center'
        display={{ base: 'none', md: 'initial' }}
        justifyContent='center'
        p={{
          base: 'md',
          sm: 'md',
          md: 'lg',
          lg: 'xl',
        }}
        width='50%'
      >
        <Image
          alt='intro image'
          src={LoginImage}
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            borderRadius: '20px',
            objectPosition: '25% 50%',
          }}
        />
      </Flex>
      <Flex
        flexDir='column'
        p={{
          base: 'md',
          sm: 'md',
          md: 'lg',
          lg: 'xl',
        }}
        width={{ sm: '100%', md: '50%' }}
      >
        <Flex justifyContent='flex-end' mt='-md'>
          <LanguageSelector mt='md' pb={{ base: '20px', md: 'none' }} />
        </Flex>
        <Flex flexDir='column' gap='2.5rem'>
          <Image alt='logo image' height={873} src={Logo} width={289} />
          <Box mb='2'>
            <Heading fontWeight={700} size='lg'>
              👋🏻 {t('titles.welcome')}
            </Heading>
          </Box>
          <Box>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Stack gap={3}>
                <FormControl isInvalid={!!errors.email}>
                  <Input
                    {...register('email', {
                      required: t('validation.required'),
                    })}
                    data-cy='email'
                    fontSize='md'
                    id='email'
                    placeholder={t('placeholders.email')}
                    size='lg'
                    type='email'
                  />
                  {errors.email && (
                    <FormErrorMessage>
                      {errors.email.message as string}
                    </FormErrorMessage>
                  )}
                </FormControl>

                <FormControl isInvalid={!!errors.password}>
                  <InputGroup>
                    <Input
                      {...register('password', {
                        required: t('validation.required'),
                      })}
                      data-cy='password'
                      fontSize='md'
                      id='password'
                      placeholder={t('placeholders.password')}
                      size='lg'
                      type={show ? 'text' : 'password'}
                    />
                    <InputRightElement height='full'>
                      {show ? (
                        <Icon
                          as={IoClose}
                          color='gray.500'
                          onClick={toggleShowPassword}
                        />
                      ) : (
                        <Icon
                          as={IoEye}
                          color='gray.300'
                          onClick={toggleShowPassword}
                        />
                      )}
                    </InputRightElement>
                  </InputGroup>
                  {errors.password && (
                    <FormErrorMessage>
                      {errors.password.message as string}
                    </FormErrorMessage>
                  )}
                </FormControl>

                <Box
                  alignItems='center'
                  display='flex'
                  justifyContent='space-between'
                >
                  <Link
                    color='electricBlue.500'
                    data-cy='forgot-password'
                    fontWeight={600}
                    href='/auth/forgot-password'
                    textDecoration='underline'
                  >
                    {t('forgotPassword')}
                  </Link>
                </Box>
              </Stack>

              <Button
                colorScheme='electricBlue'
                data-cy='submit'
                fontSize='md'
                isLoading={isSigningIn}
                minWidth={200}
                mt='3rem'
                size='lg'
                type='submit'
                variant='solid'
                width='full'
              >
                {t('buttons.login')}
              </Button>
            </form>
          </Box>
        </Flex>
        <Flex justifyContent='center'>
          <FooterLinks />
        </Flex>
      </Flex>
    </Flex>
  );
}
