import React, { FC } from 'react';

import { Box, Spinner, Text } from '@chakra-ui/react';

const LoadingSpinner: FC<{
  label?: string;
  isFloating?: boolean;
  size?: string;
}> = ({ label, isFloating = false, size = 'xl' }) => {
  return (
    <Box
      height='100%'
      left={isFloating ? 0 : undefined}
      position={isFloating ? 'absolute' : undefined}
      top={isFloating ? 0 : undefined}
      width='100%'
      zIndex={3}
    >
      <Box
        left='50%'
        position={isFloating ? 'absolute' : undefined}
        textAlign='center'
        top='50%'
        transform='translate(-50%, -50%)'
      >
        <Spinner color='electricBlue.500' size={size} />
        {label && <Text>{label}</Text>}
      </Box>
    </Box>
  );
};

export default LoadingSpinner;
