import React, { FC } from 'react';

import { Select, Props } from 'chakra-react-select';

export type Option = {
  label: string;
  value: any;
};

const ReactSelect: FC<Props> = ({ ...props }) => {
  return (
    <Select
      chakraStyles={{
        control: (provided) => ({
          ...provided,
          fontSize: 'base',
          '> div': {
            paddingInlineStart: 'md',
          },
        }),
        option: (provided) => ({ ...provided, fontSize: 'base' }),
        placeholder: (provided) => ({
          ...provided,
          WebkitPaddingStart: 0,
          paddingInlineStart: 0,
        }),
      }}
      isClearable
      menuPlacement='auto'
      useBasicStyles
      {...props}
    />
  );
};

export default ReactSelect;
