import { Box, Link } from '@chakra-ui/react';
import useTranslation from 'next-translate/useTranslation';

export default function FooterLinks() {
  const { t } = useTranslation('common');
  return (
    <Box
      alignItems='center'
      display='flex'
      gap='32px'
      justifyContent='space-between'
      mt='md'
    >
      <Link
        color='electricBlue.500'
        fontSize='sm'
        fontWeight={600}
        href='https://www.walkingthetalk.com/privacy'
        isExternal
        textDecoration='underline'
      >
        {t('footerLinks.privacyPolicy')}
      </Link>
      {/* <Link
        color='electricBlue.500'
        fontSize='sm'
        fontWeight={600}
        href='/auth/forgot-password'
        textDecoration='underline'
      >
        {t('footerLinks.termsConditions')}
      </Link>
      <Link
        color='electricBlue.500'
        fontSize='sm'
        fontWeight={600}
        href='/auth/forgot-password'
        textDecoration='underline'
      >
        {t('footerLinks.help')}
      </Link> */}
    </Box>
  );
}
