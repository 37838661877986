import { FC, useCallback, useEffect, useMemo, useState } from 'react';

import { Box, BoxProps } from '@chakra-ui/react';
import moment from 'moment';
import setLanguage from 'next-translate/setLanguage';
import useTranslation from 'next-translate/useTranslation';
import ReactFlagsSelect from 'react-flags-select';
import { CustomLabels } from 'react-flags-select/build/types';

import { LanguageCodeEnum } from '@/generated/core.graphql';
import LanguagesList from '@/views/admin/Surveys/SurveyCreation/LanguagesList';

import 'moment/locale/pt';
import 'moment/locale/es';
import 'moment/locale/en-gb';

type Props = BoxProps & {
  onLanguageChange?: (language: LanguageCodeEnum) => void;
  defaultLanguage?: LanguageCodeEnum;
  changeLocale?: boolean;
};

const LanguageSelector: FC<Props> = ({
  onLanguageChange,
  defaultLanguage = LanguageCodeEnum.EnUs,
  changeLocale = true,
  ...rest
}): JSX.Element => {
  const { lang } = useTranslation();
  const [selectedCountry, setSelectedCountry] = useState('');

  useEffect(() => {
    const value = lang === 'en' ? defaultLanguage : lang;
    const code = LanguagesList.find(
      (l) => l.languageCode === value
    )?.countryCode;

    setSelectedCountry(code);
  }, [defaultLanguage, lang]);

  const customLabels: CustomLabels = useMemo(() => {
    const labels = {};

    for (let i = 0; i < LanguagesList.length; i += 1) {
      labels[LanguagesList[i].countryCode] = LanguagesList[i].language;
    }

    return labels;
  }, []);

  const onCountryChanged = useCallback(
    (countryCode: string) => {
      const { languageCode } = LanguagesList.find(
        (l) => l.countryCode === countryCode
      );

      if (onLanguageChange) {
        onLanguageChange(languageCode);
        setSelectedCountry(countryCode);
        return;
      }

      const code =
        languageCode === LanguageCodeEnum.EnGb ||
        languageCode === LanguageCodeEnum.EnUs
          ? 'en'
          : languageCode;

      if (changeLocale) {
        moment.locale(code);
      }

      setLanguage(code);
      setSelectedCountry(countryCode);
    },
    [changeLocale, onLanguageChange]
  );

  return (
    <Box {...rest}>
      <ReactFlagsSelect
        className='languageSelector'
        countries={LanguagesList.map((l) => l.countryCode)}
        customLabels={customLabels}
        fullWidth={false}
        onSelect={onCountryChanged}
        placeholder='Select Language'
        selectButtonClassName='languageSelector--button'
        selected={selectedCountry}
      />
    </Box>
  );
};

export default LanguageSelector;
